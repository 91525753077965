<template>
  <div class="mallDetail">
    <div class="introImg">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <div
          class="oSwiper"
          v-for="(item, index) in goodsDetail.pictures"
          :key="index"
        >
          <van-swipe-item><img :src="item" alt="" /></van-swipe-item>
        </div>
      </van-swipe>
    </div>
    <div class="main">
      <div class="mallDetail-title">{{ goodsDetail.title }}</div>
      <div class="points">
        <div class="points-l">
          {{ goodsDetail.pointsPrice
          }}{{ type == "积分商城" ? "积分" : "积分" }}
        </div>
        <div class="points-r"></div>
      </div>
      <div class="selectKind" @click="showDialog">
        <div class="selectKind-l">选择规格/数量</div>
        <div class="selectKind-r">
          <img src="@/assets/img/tip.png" alt="" />
        </div>
      </div>
      <div class="line"></div>
      <div class="detail">
        <div class="detailTitle">
          <div class="title-text">领取详情</div>
        </div>
        <div class="body" v-html="goodsDetail.detailContent"></div>
      </div>
    </div>
    <div class="exchange" @click="showDialog">立即兑换</div>
    <v-popup v-model="isPopup">
      <div class="dialog">
        <div class="kinds">
          <div class="kinds-t">
            <div class="kinds-tl">
              <img :src="goodsDetail.kindImg" alt="" />
            </div>
            <div class="kinds-tr">
              <div class="title">{{ goodsDetail.title }}</div>
              <div class="price">
                {{ goodsDetail.pointsPrice
                }}{{ type == "积分商城" ? "积分" : "积分" }}（库存 :{{
                  goodsDetail.stockKind
                }}）
              </div>
            </div>
          </div>
          <div class="kinds-c">
            <!-- <div class="title">颜色</div> -->
            <div class="kindsBody">
              <div
                v-for="(item, index) in goodsDetail.skuList"
                :class="['item', { active: item.isSelected }]"
                @click="selectKind(item, index)"
                :key="index"
              >
                {{ item.skuName }}
              </div>
            </div>
          </div>
          <div class="kinds-b">
            <div class="title">数量</div>
            <div class="kindsBody">
              <v-stepper
                v-model="form.num"
                :max="goodsDetail.stockKind"
              ></v-stepper>
            </div>
          </div>
        </div>
        <div class="change" @click="changeTip">立即兑换</div>
      </div>
    </v-popup>
    <v-dialog v-model="isDialog" title="提示" @confirm="confirm">
      <div class="tips">
        <div class="tips-t">
          确定要花{{ total }}{{ type == "积分商城" ? "积分" : "积分" }}兑换{{
            form.num
          }}个{{ goodsDetail.skuName }}商品？
        </div>
        <div class="tips-b">
          <div class="tips-bl">
            <img src="@/assets/img/notice.png" alt="" />
          </div>
          <div class="tips-br">兑换成功后，不接受退换货处理</div>
        </div>
      </div>
    </v-dialog>
    <v-dialog
      class="dialog2"
      v-model="showDialog2"
      :showConfirmButton="false"
      :showCancelButton="false"
      :closeOnClickOverlay="true"
    >
      <p class="dialog-content">您还未申请志愿者</p>
      <div class="dialog-btn" @click="apply">
        <div class="btn">立即申请</div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { getGoodsDetailUrl, getChangeUrl, volunteerUrl } from "./api.js";
import { toRegister } from "@/utils/common.js";
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";
import { gloabalCount } from "@/utils/common.js";
Vue.use(Swipe);
Vue.use(SwipeItem);
var moment = require("moment");
export default {
  name: "shopDetail",
  data() {
    return {
      errorMsg: "",
      showDialog2: false,
      isPopup: false,
      isDialog: false,
      form: {
        num: 1,
        id: "",
        communityId: "",
        roomId: "",
        userId: "",
        tenantId: "",
        type: "scene",
        skuId: "",
      },
      id: "",
      type: "",
      goodsDetail: {},
      orderId: "",
    };
  },
  components: {},
  destroyed() {},
  computed: {
    total() {
      if (this.form.num || this.form.num == 0) {
        return (
          Number(this.form.num) *
          Number(this.goodsDetail.pointsPrice).toFixed(2)
        );
      } else {
        return "";
      }
    },
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
  },
  mounted() {
    this.getGoodsDetail();
    gloabalCount(this.id, 18, 1);
  },
  methods: {
    // 判断当前用户是否志愿者,若不是跳转申请志愿者界面
    async getVolunteer() {
      this.errorMsg = "";
      let params = {
        userId: this.userId,
      };
      let res = await this.$axios.post(`${volunteerUrl}`, null, {
        params: params,
      });
      if (res.code === 200 && res.success) {
        return;
      }
      this.errorMsg = res.msg;
      /** start 时间银行特殊处理 */
      if (this.type === "志愿积分商城" && this.errorMsg) {
        this.showDialog2 = true;
        return;
      }
      /** end */
    },
    // 申请
    apply() {
      this.$router.push({
        name: "applyVolunteer",
        query: {
          userId: this.userId,
        },
      });
    },
    changeTip() {
      if (this.form.num == 0) {
        this.$toast({ message: "请选择购买数量", duration: 2000 });
        return;
      }
      this.isPopup = false;
      this.isDialog = true;
    },
    selectKind(item, indexs) {
      console.log(this.goodsDetail.skuList, 22);
      if (this.goodsDetail.skuList && this.goodsDetail.skuList.length) {
        this.goodsDetail.skuList.forEach((ele, index) => {
          this.goodsDetail.skuList[index].isSelected = false;
        });
        this.goodsDetail.skuList[indexs].isSelected = true;
        this.goodsDetail.kindImg = item.skuImageUrl;
        this.form.skuId = item.id;
        this.goodsDetail.stockKind = item.stock;
        this.goodsDetail.skuName = item.skuName;
        if (this.goodsDetail.stockKind <= 0) {
          this.form.num = 0;
        } else {
          this.form.num = 1;
        }
      }
      this.$forceUpdate();
    },
    showDialog() {
      this.isPopup = true;
    },
    async confirm() {
      if (this.type == "志愿积分商城") {
        await this.getVolunteer();
        if (this.errorMsg == "") {
          this.changeGoods();
        }
      } else if (this.errorMsg == "") {
        this.changeGoods();
      }
    },
    changeGoods() {
      let params = {
        num: this.form.num,
        id: this.id,
        communityId: this.communityId,
        roomId: this.roomId || this.communityId || undefined,
        userId: this.userId,
        tenantId: this.tenantId,
        type: "scene",
        skuId: this.form.skuId,
      };

      this.$axios.post(`${getChangeUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.orderId = res.data.id;
          this.$toast({ message: "兑换成功", duration: 2000 });
          this.$router.replace({
            name: "exchangeFinished",
            query: {
              id: this.orderId,
            },
          });
        } else {
        }
      });
    },
    getGoodsDetail() {
      let params = {
        id: this.id,
        userId: this.userId,
      };

      this.$axios.get(`${getGoodsDetailUrl}`, { params }).then((res) => {
        console.log(res, 5555);
        if (res.code === 200) {
          this.goodsDetail = res.data;
          if (this.goodsDetail.pictures) {
            this.goodsDetail.pictures = this.goodsDetail.pictures.split(",");
            if (this.goodsDetail.pictures.length) {
              // this.goodsDetail.pictures.forEach((ele, index) => {
              //   this.goodsDetail.pictures[index] = ele.ossResize(340, 600);
              // });
            }
          }
          if (this.goodsDetail.imageUrl) {
            this.goodsDetail.imageUrl = this.$handleImg(
              340,
              340,
              this.goodsDetail.imageUrl
            );
          }
          if (this.goodsDetail.skuList && this.goodsDetail.skuList.length) {
            this.goodsDetail.skuList.forEach((ele, index) => {
              this.goodsDetail.skuList[index].skuImageUrl = this.$handleImg(
                340,
                340,
                ele.skuImageUrl
              );
              ele.isSelected = false;
            });
            this.goodsDetail.skuList[0].isSelected = true;
            this.goodsDetail.kindImg = this.goodsDetail.skuList[0].skuImageUrl;
            this.form.skuId = this.goodsDetail.skuList[0].id;
            this.goodsDetail.stockKind = this.goodsDetail.skuList[0].stock;
            this.goodsDetail.skuName = this.goodsDetail.skuList[0].skuName;
            if (this.goodsDetail.stockKind <= 0) {
              this.form.num = 0;
            }
          }
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.mallDetail {
  margin: 40px 0px 00px;
  padding-bottom: calc(
    146px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    146px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .introImg {
    height: calc(100vw / 16 * 9);
    width: 100%;
    position: relative;
    margin-bottom: 30px;
    img {
      height: 100%;
    }
  }
  .main {
    padding: 0 46px;
  }
  .mallDetail-title {
    font-size: 34px;
    margin: 32px 0 8px;
  }
  .points {
    display: flex;
    color: rgba(0, 126, 255, 1);
    font-size: 32px;
    margin: 0px 0 60px;
    justify-content: space-between;
  }
  .selectKind {
    width: 100%;
    height: 60px;
    background: #f7f7f7;
    padding: 40px;
    box-sizing: border-box;
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999;
    .selectKind-r {
      width: 30px;
      height: 30px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .line {
    width: 100%;
    background: #f7f7f7;
    height: 2px;
    margin: 60px 0;
  }
  .detail {
    .detailTitle {
      height: 44px;
      position: relative;
      font-size: 32px;
      font-weight: bold;
      color: #000000d9;
      line-height: 44px;
      .title-text {
        width: 174px;
        text-align: center;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 174px;
        height: 16px;
        background: #e2e2e2;
        border-radius: 7px;
      }
    }
    // ::v-deep .detailBody {
    //   padding: 20px 0;
    //   font-size: 28px !important;
    //   img {
    //     width: 100%;
    //   }
    //   p,
    //   h1,
    //   span {
    //     font-size: 28px !important;
    //   }
    // }
    .van-swipe-item {
      text-align: center;
    }
  }
  .exchange {
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 550px;
    height: 66px;
    font-size: 28px;
    line-height: 66px;
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
    border-radius: 10px;
    margin-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    margin-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  }
  .dialog2 {
    font-size: 34px;
    font-weight: bold;
    color: #000000d9;
    p.dialog-content {
      text-align: center;
      padding: 56px 0 92px 0;
    }
    div.dialog-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 56px;
      .btn {
        width: 440px;
        height: 66px;
        text-align: center;
        line-height: 66px;
        color: #ffffff;
        font-weight: bold;
        font-size: 30px;
        background: linear-gradient(180deg, #73a8ff 0%, #307cff 100%);
        border-radius: 10px;
        &.close {
          background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
          color: #a7a7a7ff;
        }
      }
    }
  }
  .dialog {
    position: relative;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    .change {
      // width: 90%;
      box-sizing: border-box;
      // height: 90px;
      // border-radius: 10px;
      // background: rgba(0, 126, 255, 1);
      // font-size: 32px;
      // line-height: 90px;
      // color: #fff;
      // text-align: center;
      position: absolute;
      bottom: 60px;
      left: 0;
      right: 0;
      margin: auto;
      width: 550px;
      height: 66px;
      font-size: 28px;
      line-height: 66px;
      text-align: center;
      font-weight: bold;
      color: #ffffff;
      background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
      border-radius: 10px;
    }
  }
  .kinds {
    padding: 40px 40px 180px;
    height: 100%;
    box-sizing: border-box;
    overflow: scroll;
    .title {
      font-size: 34px;
      margin: 40px 0;
    }
    .kinds-t {
      display: flex;
    }
    .kinds-tl {
      width: 200px;
      height: 200px;
      margin-right: 20px;
      img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
      }
    }
    .kinds-tr {
      font-size: 34px;
      .title {
        margin: 40px 0;
      }
      .price {
        font-size: 30px;
        color: rgba(0, 126, 255, 1);
      }
    }
    .kinds-c {
      margin: 80px 0 0;
      .kindsBody {
        display: flex;
        flex-wrap: wrap;
        .item {
          font-size: 30px;
          border-radius: 10px;
          padding: 10px 40px;
          margin: 0 20px 20px 0;
          border: 1px solid #999;
          color: #999;
        }
        .active {
          border: 1px solid rgba(0, 126, 255, 1);
          color: rgba(0, 126, 255, 1);
        }
      }
    }
  }
  .tips {
    text-align: center;
    .tips-t {
      font-size: 30px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 42px;
      margin: 40px 0;
    }
    .tips-b {
      display: flex;
      margin: 40px 0;
      justify-content: center;
      align-items: center;
      .tips-bl {
        width: 30px;
        height: 30px;
        font-size: 0;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .tips-br {
        font-size: 24px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 34px;
      }
    }
  }
}
</style>
<style lang="less">
.mallDetail {
  .body {
    padding: 20px 0;
    // font-size: 28px;
    img {
      width: 100%;
    }
    font-size: 28px !important;
    p,
    h1,
    span {
      font-size: 28px !important;
    }
  }
  .van-swipe-item {
    text-align: center;
  }
}
</style>
